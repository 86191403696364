import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

// Context
import PortfolioContext from '../../context/context';

// Constants
import trippy from '../../images/rabbit-hole.gif';
import brand from '../../images/icon.svg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { img, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} duration={1000} delay={500} distance="30px">
          <div className="brand">
            <img src={brand} alt="CircleDot brand" />
          </div>
        </Fade>
        <div bottom duration={1000} delay={600} distance="30px">
          <div className="main-hero">
            <h3 className="hero-subtitle">
              Devshop working on <span className="text-color-main">blockchain </span> and{' '}
              <span className="text-color-main">zero knowledge </span> solutions
            </h3>
            <img src={trippy} className="hero-image" alt="rabbit hole" />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Header;
